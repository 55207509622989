.bg-card-hover {
    background: white;
}

.bg-card-hover:hover {
    background: #d8d8d8;
}

.checkmark {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: green;
    stroke-miterlimit: 10;
    stroke-dashoffset: 0;
    margin: 10% auto;
  }